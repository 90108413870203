// assets
import { IconBrandTelegram, IconWriting, IconScript, IconReport } from '@tabler/icons';

// constant
const icons = { IconBrandTelegram, IconWriting, IconScript, IconReport };
let messages = {};
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
if (process.env.REACT_APP_WEB_ENV != 'production') {
    messages = {
        id: 'messages',
        title: 'Informācija',
        type: 'group',
        children: [
            /*
            {
                id: 'legal',
                title: 'Mani iesniegumi',
                type: 'item',
                url: '/',
                icon: icons.IconBrandTelegram,
                breadcrumbs: false
            },
            */
            {
                id: 'report',
                title: 'Manas izziņas',
                type: 'item',
                url: '/report',
                icon: icons.IconReport,
                breadcrumbs: false
            },
            {
                id: 'onboarding',
                title: 'Lietošanas instrukcija',
                type: 'item',
                url: '/onboarding',
                icon: icons.IconWriting,
                breadcrumbs: false
            },
            {
                id: 'rules',
                title: 'Lietošanas noteikumi',
                type: 'item',
                url: '/rules',
                icon: icons.IconScript,
                breadcrumbs: false
            }
        ]
    };
} else {
    messages = {
        id: 'messages',
        title: 'Informācija',
        type: 'group',
        children: [
            {
                id: 'report',
                title: 'Manas izziņas',
                type: 'item',
                url: '/report',
                icon: icons.IconReport,
                breadcrumbs: false
            },
            {
                id: 'rules',
                title: 'Lietošanas noteikumi',
                type: 'item',
                url: '/rules',
                icon: icons.IconScript,
                breadcrumbs: false
            }
        ]
    };
}
export default messages;
